<template>
  <el-main class="rh-medform">
    <form-fcontinuecreate :routes="fcontRoutes" :ismeddetail="true" />
  </el-main>
</template>
<script>
import FormFcontinuecreate from "@/components/rh/formulaires/FormFcontinuecreate";

export default {
  components: { "form-fcontinuecreate": FormFcontinuecreate },
  computed: {
    fcontRoutes() {
      return {
        toedit: {
          name: "med_fcontinues_edit",
          params: {
            idmed: this.$route.params.idmed,
            idfc: this.$store.state.fc.fcontinueLastCreatedId,
          },
        },
        tolist: {
          name: "med_fcontinues",
          params: {
            idmed: this.$route.params.idmed,
          },
        },
      };
    },
  },
};
</script>
